import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../index.css'

import Layout from '../../components/Layout';
import { Select, Affix } from 'antd';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TumbonAddr from '../../util/TumbonAddr'

import getProvinceName from '../../util/getProvinceName';
import api from '../../util/api';

const { Option } = Select;

const yearSemesterList = [
  { text: `2567`, value: `2567` },
  { text: `2566`, value: `2566` },
  { text: `2565`, value: `2565` },
  { text: `2564`, value: `2564` },
  { text: `2563`, value: `2563` },
  { text: `2562`, value: `2562` },
  { text: `2561`, value: `2561` },
]

const PageDashboard = (props) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [province, setProvince] = useState([])
  const [listProvince, setListProvince] = useState([])
  const [tabindex, setTabIndex] = useState(0)
  const [province_view_filter, setProvinceViewFilter] = useState({
    replace: "อำเภอ",
    year: "2567",
    value: [null, null, null],
    index: null,
  })
  const [year_view_filter, setYearViewFilter] = useState({
    replace: "ปีการศึกษา",
    value: ['2567','2566', '2565', '2564', '2563'],
    index: null,
  })

  const [result, setResult] = useState({
    total: { text: "", value: [0, 0, 0], unit: 'คน' },
    total_school: { text: "", value: [0, 0, 0], unit: 'รร.' },
    cct: { text: "ทุนเสมอภาค", value: [0, 0, 0], unit: 'คน' },
    krt: { text: "ทุนครูรัก(ษ์)ถิ่น", value: [0, 0, 0], unit: 'คน' },
    vec: { text: "ทุนนวัตกรรมสายอาชีพชั้นสูง", value: [0, 0, 0], unit: 'คน' },
    tps: { text: "ทุนพระกนิษฐาสัมมาชีพ", value: [0, 0, 0], unit: 'คน' },
    target_group: { text: "ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน", value: [0, 0, 0], unit: 'คน' },
    tsqp: { text: "โครงการโรงเรียนพัฒนาตนเอง", value: [0, 0, 0], unit: 'รร.' },
    abe: { text: "การจัดการเชิงพื้นที่", value: [0, 0, 0], unit: 'คน' },
    fsql: { text: "โรงเรียนในโครงการ FSQL", value: [0, 0, 0], unit: 'รร.' },
    pisa: { text: "PISA for school (นักเรียนช้างเผือก)", value: [0, 0, 0], unit: 'คน' },
  })

  useEffect(async () => {
    let res_profile = await api.getProfileDb()
    let _provincename = '10'
    if (res_profile?.profile?.profile?.provinceId) {
      setLoading(false)
      _provincename = getProvinceName(res_profile?.profile?.profile?.provinceId)
      setProvince(_provincename)
    }

    let listprovince = [...new Set(TumbonAddr.filter(item => item.provincename === _provincename).map(item => { return `${item.cityname}_${item.cityid}` }))]
      .map(item => { return { city_name: item.split("_")[0], city_id: item.split("_")[1] } }).sort((a, b) => {
        if (a.city_name < b.city_name) {
          return -1;
        }
        if (a.city_name > b.city_name) {
          return 1;
        }
        return 0;
      });
    setListProvince(listprovince)

    let [
      res25612,
      res25621,
      res25622,
      res25631,
      res25632,
      res25641,
      res25642,
      res25651,
      res25652,
      res25661,
      res25662,
      res25671,
    ] = await Promise.all([
      api.getInitData(`/call-eef-dashboard-2561-2`),
      api.getInitData(`/call-eef-dashboard-2562-1`),
      api.getInitData(`/call-eef-dashboard-2562-2`),
      api.getInitData(`/call-eef-dashboard-2563-1`),
      api.getInitData(`/call-eef-dashboard-2563-2`),
      api.getInitData(`/call-eef-dashboard-2564-1`),
      api.getInitData(`/call-eef-dashboard-2564-2`),
      api.getInitData(`/call-eef-dashboard-2565-1`),
      api.getInitData(`/call-eef-dashboard-2565-2`),
      api.getInitData(`/call-eef-dashboard-2566-1`),
      api.getInitData(`/call-eef-dashboard-2566-2`),
      api.getInitData(`/call-eef-dashboard-2567-1`),
    ])
    setData({
      data25612: res25612.data.filter(item => item.province_name === _provincename),
      data25621: res25621.data.filter(item => item.province_name === _provincename),
      data25622: res25622.data.filter(item => item.province_name === _provincename),
      data25631: res25631.data.filter(item => item.province_name === _provincename),
      data25632: res25632.data.filter(item => item.province_name === _provincename),
      data25641: res25641.data.filter(item => item.province_name === _provincename),
      data25642: res25642.data.filter(item => item.province_name === _provincename),
      data25651: res25651.data.filter(item => item.province_name === _provincename),
      data25652: res25652.data.filter(item => item.province_name === _provincename),
      data25661: res25661.data.filter(item => item.province_name === _provincename),
      data25662: res25662.data.filter(item => item.province_name === _provincename),
      data25671: res25671.data.filter(item => item.province_name === _provincename),
    })
    setLoading(false)
  }, [])

  useEffect(() => {
    if (tabindex === 0) {
      let p = province_view_filter
      p.value.map((id, index) => {
        if (id) {
          p.year === '2565' || p.year === '2567' ?
            result.cct.value[index] = data[`data${p.year}1`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.exjon, 0) :
            result.cct.value[index] = data[`data${p.year}2`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.exjon, 0)
          result.krt.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.krt, 0)
          result.vec.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.vec, 0)
          result.tps.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.tps, 0)
          result.target_group.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.target_group, 0)
          result.tsqp.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.tsqp, 0)
          result.abe.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + (cur.abe_ecd + cur.abe_oosc), 0)
          result.fsql.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + cur.fsql, 0)
          result.pisa.value[index] = data[`data${p.year}${p.year === '2561' ? '2' : '1'}`].filter(item => item.cityid === id).reduce((acc, cur) => acc + (cur?.pisa ? cur.pisa : 0), 0)
          result.total.value[index] = result.cct.value[index] +
            result.krt.value[index] +
            result.vec.value[index] +
            result.tps.value[index] +
            result.target_group.value[index] +
            result.abe.value[index] +
            result.pisa.value[index]
          result.total_school.value[index] = result.tsqp.value[index] 
          //  + result.fsql.value[index]
        }
      })
      setResult({ ...result })
    }
  }, [province_view_filter, tabindex])

  useEffect(() => {
    if (tabindex === 1) {
      year_view_filter.value.map((year, index) => {
        if (year) {
          year === '2565' ?
            result.cct.value[index] = data[`data${year}1`].reduce((acc, cur) => acc + cur.exjon, 0) :
            result.cct.value[index] = data[`data${year}2`].reduce((acc, cur) => acc + cur.exjon, 0)
          result.krt.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + cur.krt, 0)
          result.vec.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + cur.vec, 0)
          result.tps.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + cur.tps, 0)
          result.target_group.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + cur.target_group, 0)
          result.tsqp.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + cur.tsqp, 0)
          result.abe.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + (cur.abe_ecd + cur.abe_oosc), 0)
          result.fsql.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + cur.fsql, 0)
          result.pisa.value[index] = data[`data${year}${year === '2561' ? '2' : '1'}`].reduce((acc, cur) => acc + (cur?.pisa ? cur.pisa : 0), 0)
          result.total.value[index] = result.cct.value[index] +
            result.krt.value[index] +
            result.vec.value[index] +
            result.tps.value[index] +
            result.target_group.value[index] +
            result.abe.value[index] +
            result.pisa.value[index]
          result.total_school.value[index] = result.tsqp.value[index] 
            // + result.fsql.value[index]
        }
      })
      setResult({ ...result })
    }
  }, [year_view_filter, tabindex])

  const MainCard = ({ student, school, unit_student, unit_school }) => {
    return <div className='w-full xl:bg-white xl:shadow-lg xl:shadow-emerald-500/40 xl:rounded-xl text-center p-2'>
      <div className='my-12'>
        <h3 className='text-lg lg:text-2xl mb-0'>จำนวนเด็กที่ได้รับทุน</h3>
        <h1 className='text-xl lg:text-5xl font-bold text-[#144174] mb-0'>{student ? student.toLocaleString("en-US") : "-"}</h1>
        <h3 className='text-lg lg:text-2xl text-[#144174] mb-0'>{unit_student}</h3>
        <div className='border-dotted border-b border-neutral-400 w-3/4 mx-auto my-6'></div>
        <h3 className='text-lg lg:text-xl mb-0'>จำนวนโรงเรียน TSQP</h3>
        <h1 className='text-xl lg:text-5xl font-bold text-[#068769] mb-0'>{school ? school.toLocaleString("en-US") : "-"}</h1>
        <h3 className='text-lg lg:text-2xl text-[#068769] mb-0'>{unit_school}</h3>
      </div>
    </div>
  }

  const RowCell = ({ text, value, unit }) => {
    return (
      <div className='grid grid-cols-3 lg:grid-cols-4 my-4 lg:my-1'>
        <div className='col-span-3 col-start-1 lg:col-end-2'>
          <h2 className='text-2xl text-center lg:text-left'>{text}</h2>
        </div>
        <div className='col-span-1 lg:col-start-2 lg:col-end-3 text-center'>
          <h2 className='text-2xl'>{value[0] ? `${value[0].toLocaleString("en-US")} ${unit}` : "-"}</h2>
        </div>
        <div className='col-span-1 lg:col-start-3 lg:col-end-4 text-center'>
          <h2 className='text-2xl'>{value[1] ? `${value[1].toLocaleString("en-US")} ${unit}` : "-"}</h2>
        </div>
        <div className='col-span-1 lg:col-start-4 lg:col-end-5 text-center'>
          <h2 className='text-2xl'>{value[2] ? `${value[2].toLocaleString("en-US")} ${unit}` : "-"}</h2>
        </div>
      </div>
    )
  }

  if (loading) {
    return (<Layout isHideFooter>
      <div className='relative overflow-hidden'>
        <div className="w-[100vw] h-[100vh] flex flex-col justify-center items-center">
          <div className="text-center" role="status">
            <svg className="inline mr-2 w-32 h-32 text-gray-200 animate-spin dark:text-gray-200 fill-[#038967]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
          <div className="text-2xl text-[#038967] font-bold text-center mt-4">  Loading ...</div>
        </div>
      </div>
    </Layout>)
  }
  return (
    <Layout>
      <div className='bg-white'>
        <div className='flex flex-col w-full' >
          <h1 className="font-bold text-6xl text-center text-black mt-[150px] w-full">เปรียบเทียบข้อมูลการให้ทุนของ กสศ.</h1>
          <h1 className="font-bold text-4xl text-center text-[#144174] mt-4 w-full">{`จังหวัด ${province}`}</h1>
          <Tabs className='my-4' value={tabindex} onChange={(event, newValue) => setTabIndex(newValue)} centered>
            <Tab label={<span className='text-lg'>เปรียบเทียบอำเภอ</span>} />
            <Tab label={<span className='text-lg'>เปรียบเทียบรายปี</span>} />
          </Tabs>
          {
            tabindex === 0 && (
              <div className='text-center mx-auto my-4'>
                <h5 className="text-lg font-bold my-auto ml-[9px]" >ปีการศึกษา </h5>
                <Select
                  className='new-design-bold-select'
                  showSearch
                  placeholder="ปีการศึกษา"
                  defaultValue={province_view_filter.year}
                  value={province_view_filter.year}
                  size={'large'}
                  style={{ width: 180, borderColor: 'black' }}
                  onChange={(val) => {
                    setProvinceViewFilter({ ...province_view_filter, year: val })
                  }}
                >
                  {
                    [...new Set(yearSemesterList)].map(x => <Option value={x.value}>{x.text}</Option>)
                  }
                </Select>
              </div>
            )
          }
        </div>
        <div className="max-w-[1600px] m-auto p-8">
          <Affix offsetTop={100}>
            <div className='grid grid-cols-3 lg:grid-cols-4'>
              <div className='col-span-1 lg:col-start-2 lg:col-end-3 px-4'>
                <Select
                  className='new-design-bold-select'
                  showSearch
                  optionFilterProp="children"
                  placeholder={tabindex === 0 ? province_view_filter.replace : year_view_filter.replace}
                  defaultValue={tabindex === 0 ? province_view_filter.value[0] : year_view_filter.value[0]}
                  value={tabindex === 0 ? province_view_filter.value[0] : year_view_filter.value[0]}
                  style={{ width: '100%', marginBottom: '15px', textAlign: 'center' }}
                  size={'large'}
                  onChange={(val) => {
                    if (tabindex === 0) {
                      setProvinceViewFilter({ ...province_view_filter, value: [val, province_view_filter.value[1], province_view_filter.value[2]] })
                    } else {
                      setYearViewFilter({ ...year_view_filter, value: [val, year_view_filter.value[1], year_view_filter.value[2]] })
                    }
                  }}
                >
                  {
                    tabindex === 0 ? [...new Set(listProvince)].map(x => <Option value={x.city_id}>{x.city_name}</Option>) :
                      [...new Set(yearSemesterList)].map(x => <Option value={x.value}>{x.text}</Option>)
                  }
                </Select>
              </div>
              <div className='col-span-1 lg:col-start-3 lg:col-end-4 px-4'>
                <Select
                  className='new-design-bold-select'
                  showSearch
                  optionFilterProp="children"
                  placeholder={tabindex === 0 ? province_view_filter.replace : year_view_filter.replace}
                  defaultValue={tabindex === 0 ? province_view_filter.value[1] : year_view_filter.value[1]}
                  value={tabindex === 0 ? province_view_filter.value[1] : year_view_filter.value[1]}
                  style={{ width: '100%', marginBottom: '15px', textAlign: 'center' }}
                  size={'large'}
                  onChange={(val) => {
                    if (tabindex === 0) {
                      setProvinceViewFilter({ ...province_view_filter, value: [province_view_filter.value[0], val, province_view_filter.value[2]] })
                    } else {
                      setYearViewFilter({ ...year_view_filter, value: [year_view_filter.value[0], val, year_view_filter.value[2]] })
                    }
                  }}
                >
                  {
                    tabindex === 0 ? [...new Set(listProvince)].map(x => <Option value={x.city_id}>{x.city_name}</Option>) :
                      [...new Set(yearSemesterList)].map(x => <Option value={x.value}>{x.text}</Option>)
                  }
                </Select>
              </div>
              <div className='col-span-1 lg:col-start-4 lg:col-end-5 px-4'>
                <Select
                  className='new-design-bold-select'
                  showSearch
                  optionFilterProp="children"
                  placeholder={tabindex === 0 ? province_view_filter.replace : year_view_filter.replace}
                  defaultValue={tabindex === 0 ? province_view_filter.value[2] : year_view_filter.value[2]}
                  value={tabindex === 0 ? province_view_filter.value[2] : year_view_filter.value[2]}
                  style={{ width: '100%', marginBottom: '15px', textAlign: 'center' }}
                  size={'large'}
                  onChange={(val) => {
                    if (tabindex === 0) {
                      setProvinceViewFilter({ ...province_view_filter, value: [province_view_filter.value[0], province_view_filter.value[1], val] })
                    } else {
                      setYearViewFilter({ ...year_view_filter, value: [year_view_filter.value[0], year_view_filter.value[1], val] })
                    }
                  }}
                >
                  {
                    tabindex === 0 ? [...new Set(listProvince)].map(x => <Option value={x.city_id}>{x.city_name}</Option>) :
                      [...new Set(yearSemesterList)].map(x => <Option value={x.value}>{x.text}</Option>)
                  }
                </Select>
              </div>
            </div>
          </Affix>
          <div className='grid grid-cols-3 lg:grid-cols-4'>
            <div className='col-span-1 lg:col-start-2 lg:col-end-3 px-4'>
              <MainCard student={result.total.value[0]} school={result.total_school.value[0]} unit_student={result.total.unit} unit_school={result.total_school.unit} />
            </div>
            <div className='col-span-1 lg:col-start-3 lg:col-end-4 px-4'>
              <MainCard student={result.total.value[1]} school={result.total_school.value[1]} unit_student={result.total.unit} unit_school={result.total_school.unit} />
            </div>
            <div className='col-span-1 lg:col-start-4 lg:col-end-5 px-4'>
              <MainCard student={result.total.value[2]} school={result.total_school.value[2]} unit_student={result.total.unit} unit_school={result.total_school.unit} />
            </div>
          </div>
          {
            tabindex === 0 && (
              <>
                {/* <div className='grid grid-cols-4 mt-6 mb-2'>
                  <div className='col-start-1 col-end-2'> */}
                <h2 className='text-4xl font-bold text-[#fa6814] my-0'>ปี {province_view_filter.year}</h2>
                {/* <h4 className='text-2xl my-0'>จำนวนเด็กที่ได้รับทุน</h4>
                  </div>
                  <div className='col-start-2 col-end-3 text-center'>
                    <h2 className='text-3xl font-bold text-[#144174] my-0'>53,214 <span className='text-2xl font-normal'>คน</span></h2>
                    <h4 className='text-3xl font-bold text-[#068769] my-0'>15 <span className='text-2xl font-normal'>โรงเรียน</span></h4>
                  </div>
                  <div className='col-start-3 col-end-4 text-center'>
                    <h2 className='text-3xl font-bold text-[#144174] my-0'>53,214 <span className='text-2xl font-normal'>คน</span></h2>
                    <h4 className='text-3xl font-bold text-[#068769] my-0'>15 <span className='text-2xl font-normal'>โรงเรียน</span></h4>
                  </div>
                  <div className='col-start-4 col-end-5 text-center'>
                    <h2 className='text-3xl font-bold text-[#144174] my-0'>53,214 <span className='text-2xl font-normal'>คน</span></h2>
                    <h4 className='text-3xl font-bold text-[#068769] my-0'>15 <span className='text-2xl font-normal'>โรงเรียน</span></h4>
                  </div>
                </div>
                <div className='border-b-2 border-neutral-400 mx-auto my-6'></div> */}
              </>
            )
          }
          <div>
            <h2 className='text-3xl font-bold'>ทุนสร้างโอกาส</h2>
          </div>
          <div className='border-dotted border-b border-neutral-400 mx-auto my-6'></div>
          <RowCell {...result.cct} />
          <RowCell {...result.krt} />
          <RowCell {...result.vec} />
          <RowCell {...result.tps} />
          <RowCell {...result.target_group} />
          <div className='border-b-2 border-neutral-400 mx-auto my-6'></div>
          <div>
            <h2 className='text-3xl font-bold'>นวัตกรรมต้นแบบ</h2>
          </div>
          <div className='border-dotted border-b border-neutral-400 mx-auto my-6'></div>
          <RowCell {...result.tsqp} />
          <RowCell {...result.abe} />
          <div className='border-b-2 border-neutral-400 mx-auto my-6'></div>
          {/* <div>
            <h2 className='text-3xl font-bold'>งานวิจัย</h2>
          </div>
          <div className='border-dotted border-b border-neutral-400 mx-auto my-6'></div>
          <RowCell {...result.fsql} />
          <RowCell {...result.pisa} /> */}
        </div>
      </div>
    </Layout >
  )
}

export default PageDashboard
